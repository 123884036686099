import LaunchIcon from "@mui/icons-material/Launch";
import axios from "axios";
import { Editor, EditorState, convertFromRaw } from "draft-js";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setLoader } from "../Redux/LoaderRedux";
import "../StyleSheets/carousel.css";
import "./pp.css";
import Categories from "./Categories";
import { addHeaders } from "../Redux/headerSelect";

const PhysicalProducts = () => {
  const dispatch = useDispatch();
  const [product, setProduct] = useState({});
  const [images, setImages] = useState([]);
  const load = useSelector((state) => state.loader.value);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  // fetch selected product from url
  const { id } = useParams();
  useEffect(() => {
    if (id !== undefined) {
      fetchProduct(reverseUrl(id));
    }
    // if (category) {
    //   dispatch(addHeaders(category.toUpperCase()));
    // }
  }, [id]);

  const reverseUrl = (str) => {
    return str.replace(/-/g, " ").toLowerCase();
  };

  // color list

  const fetchProduct = async (i) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/get-selected-product/${i}`
    );
    if (response.data.success) {
      const a = response.data.result;
      setImages(a?.images);
      setProduct(a);
      if (a.description !== "") {
        const rawContent = JSON.parse(a?.description);
        const contentState = convertFromRaw(rawContent);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      }

      dispatch(setLoader(false));
    }
  };

  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleThumbnailClick = (index) => {
    setSelectedIndex(index);
  };

  const thumbnailContainerRef = useRef(null);

  const scrollThumbnails = (direction) => {
    const container = thumbnailContainerRef.current;
    if (container) {
      const scrollAmount = direction === "left" ? -100 : 100;
      container.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };
  return (
    <div className="home-page">
      {/* <Categories /> */}
      <div className="physical-products">
        {!load && (
          <div className="images-div">
            {images?.length > 1 ? (
              <div className="images-prop">
                <Carousel
                  showArrows={true}
                  showStatus={false}
                  showThumbs={false}
                  autoPlay={true}
                  interval={5000}
                  selectedItem={selectedIndex}
                  onChange={(index) => setSelectedIndex(index)}
                >
                  {images?.map((p, index) => {
                    return (
                      <div key={index}>
                        <img
                          src={p.url}
                          alt="Image 1"
                          width="100%"
                          height="100%"
                        />
                      </div>
                    );
                  })}
                </Carousel>
                <div
                  className="image-thumbnail-head"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                    position: "relative",
                  }}
                >
           
                  <button
                    onClick={() => scrollThumbnails("left")}
                    style={{
                      position: "absolute",
                      left: "0",
                      zIndex: "2",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    &#9664;
                  </button>

                
                  <div
                    ref={thumbnailContainerRef}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      overflowX: "auto",
                      scrollBehavior: "smooth",
                      gap: "10px",
                      width:  window.innerWidth < 700 ? `${window.innerWidth - 100}px` : "580px",
                      padding: "10px 0px",
                      margin: "0 40px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {images?.map((image, index) => (
                      <div
                        key={index}
                        style={{
                          border:
                            index === selectedIndex
                              ? "2px solid #007BFF"
                              : "1px solid #ccc",
                          padding: "4px",
                          cursor: "pointer",
                          borderRadius: "5px",
                          flex: "0 0 auto",
                        }}
                        onClick={() => handleThumbnailClick(index)}
                      >
                        <img
                          src={image.url}
                          alt={`Thumbnail ${index + 1}`}
                          style={{
                            width: "60px",
                            height: "40px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    ))}
                  </div>

                  
                  <button
                    onClick={() => scrollThumbnails("right")}
                    style={{
                      position: "absolute",
                      right: "0",
                      zIndex: "10",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    &#9654;
                  </button>
                </div>
              </div>
            ) : (
              <>
                {images?.map((img, index) => {
                  return (
                    <div className="images-prop" key={index}>
                      <img src={img.url} />
                    </div>
                  );
                })}
              </>
            )}
            {product?.link && (
              <button
                onClick={() =>
                  window.open(product?.link, "_blank", "noopener noreferrer")
                }
              >
                Sample <LaunchIcon />
              </button>
            )}
          </div>
        )}
        <div className="label-value-section">
          {!load && (
            <>
              <div className="label-value-section-sub">
                <span className="label-value-section-value" style={enterStyle}>
                  <Editor editorState={editorState} readOnly />
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PhysicalProducts;

const enterStyle = {
  flexDirection: "column",
  alignItems: "baseline",
};
const customUsInStyle = {
  height: "20vh",
  overflowX: "auto",
  border: "1px solid #808080",
  borderRadius: "8px",
  padding: "5px",
};
const customProductStyle = {
  height: "39vh",
  overflowX: "auto",
  border: "1px solid #808080",
  borderRadius: "8px",
  padding: "5px",
};
const toast1 = (msg) => {
  toast.error(msg, {
    position: "top-right",
    autoClose: 15000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
    progressStyle: { background: "#E8DFD0" },
    theme: "colored",
    style: { background: "#808080", fontSize: "1.5rem" },
  });
};

const opts = {
  height: "240",
  width: "360",
  playerVars: {
    autoplay: 1,
  },
};
